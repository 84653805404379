import {
  defineStore
} from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    userInfo: {}
  }),
  getters: {

  },
  actions: {
    setUserInfo(data) {
      let d = data ? data : JSON.parse(localStorage.getItem('userInfo'));
      for( let i in d ) {
        if ( !['code', 'msg'].includes(i) ) {
          this.userInfo[i] = d[i];
        }
      }
    }
  }
})
