// @/router/dynamic.ts
import router from '@/router'
import { ElLoading } from "element-plus";
import { getRoutePath } from '@/api/systemsetting/SystemSettingRoute'
import { useCounterStore } from '@/store';

/**
 * 注册路由
 * 用户切换账号需移除 sessionStorage 中的 routerMap 数据
 */
export const registerRoutes = () => {
    const store = useCounterStore()
    return new Promise((resolve, reject) => {
        // 添加404页面
        // router.addRoute({
        //     path: "/:catchAll(.*)",
        //     redirect: "/404",
        //     name: "NotFound",
        // })

        if (store.userRouter.length) {
            // debugger
            // addRoutes(res.data[0].children, 'home');
            getRoutePath().then(res => {
                if (res.code == 200) {
                    store.userRouter = res.data[0].children
                    localStorage.setItem('userRouter', JSON.stringify(res.data))
                    addRoutes(res.data[0].children, 'home');
                    resolve({ query: true, name: '添加结束' });
                    router.push({ path: '/' })
                }
            })
        } else {
            getRoutePath().then(res => {
                if (res.code == 200) {
                    store.userRouter = res.data[0].children
                    localStorage.setItem('userRouter', JSON.stringify(res.data))
                }
            })
        }
    })
}

/**
 * 动态添加路由
 */
const addRoutes = (routes, parentName = "") => {
    console.log(routes, parentName);
    routes.forEach((item) => {
        const route = {
            path: item.path,
            name: item.name,
            component: () => import(`../views/${item.component}.vue`),
            meta: item.meta
        }

        parentName ? router.addRoute(parentName, route) : router.addRoute(route);
        // item.children && item.children.length>0 ? addRoutes( item.children,item.name) : console.log('没有children');;
        // console.log(item.children && item.children.length);
        if (item.children && item.children.length) {
            addRoutes(item.children, item.name);
        } else {
        }
    })

};

/**
 * 生成管理菜单
 */
// export const getAuthMenu = () => {
//     // 这里就根据路由生成后台左侧菜单
//     const routerMap = sessionData.get("routerMap");
// }