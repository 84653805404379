<template>
  <div class="outer-box">
    <el-dialog v-model="props.modelValue" @close="handleClose" width="400px">
      <template #header="{ close, titleId, titleClass }">
        <div class="header-box">
          <div :id="titleId" :class="titleClass">{{ title }}</div>
        </div>
      </template>

      <div v-if="type === 'choose'" class="choose-box">
        <company-list isShow :data="props.data" :showAdd="false" :className="'com-list'"
          @clickChoose="chooseCompany"></company-list>
      </div>

      <div v-if="type === 'create'">
        <el-form :model="formData" :rules="rules" ref="ruleFormRef">
          <el-form-item prop="name">
            <el-input v-model="formData.name" placeholder="请输入企业名称" />
          </el-form-item>
          <el-form-item>
            <div class="btn-box">
              <button class="btn" @click="handleClose">取消</button>
              <button class="btn submit" type="primary" @click="addCompany">确定</button>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <div v-if="type === 'max'">
        <div class="text">用户最多创建一个免费企业，您可以选择升级当前企业，或付费开通新企业</div>
        <company-list>
          <template #itemRight>
            <div class="btn-buy">去购买 ></div>
          </template>
        </company-list>
        <div class="btn-box">
          <button class="btn" @click="handleClose"><span>取消</span></button>
          <button class="btn submit" type="primary" @click="onSubmit"><span>确定</span></button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, computed, defineEmits, watch } from 'vue'
import { ElMessage } from 'element-plus'
import CompanyList from './CompanyList.vue'
import { checkCompany } from '@/hooks'
import { createCompany } from '@/api/systemsetting/user'
import { getInfo } from '@/api/login/login'
import { useUserStore } from '@/store/user'
import { useRouter } from 'vue-router'
const userStore = useUserStore();
const router = useRouter();

const emits = defineEmits(['update:modelValue', 'close', 'choose'])

const ruleFormRef = ref(null);
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'max'
  },
  data: {
    type: Array,
    default: () => []
  }
})


const rules = reactive({
  name: {
    required: true,
    message: '企业名称不能为空',
    trigger: 'blur',
  }
})

const title = computed(() => {
  let title = '';
  switch (props.type) {
    case 'create':
      title = '创建新企业';
      break;

    case 'choose':
      title = '请选择一个企业';
      break;

    case 'max':
      title = '免费企业超出限制';
      break;

    default:
      break;
  }
  return title;
})

const formData = reactive({ name: '' });

const addCompany = () => {
  ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      createCompany({ ...formData }).then(({ code, data }) => {
        if (code == 200) {
          ElMessage({
            message: '创建成功',
            type: 'success',
          })
          formData.name = '';
          ruleFormRef.value.clearValidate();
          checkCompany({
            userId: data.userId,
            userName: data.userName
          }).then(() => {
            router.go(0);
          });
          handleClose();
        }
      })
    }
  })
}

const handleClose = () => {
  emits('close', false);
}
const chooseCompany = (o) => {
  emits('choose', o);
}

</script>
<style lang="scss">
.outer-box {
  .el-dialog__body {
    // padding: 0 20px 20px 20px;
  }
}
</style>
<style scoped lang='scss'>
.header-box div {
  font-size: 16px;
}

.text {
  font-size: 14px;
  margin-bottom: 24px;
}

.btn-buy {
  font-size: 14px;
  color: #2E5BFC;
  cursor: pointer;
}

.btn-box {
  width: 100%;
  display: flex;
  justify-content: end;

  .btn {
    width: 60px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-family: PingFangSC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.88);
    outline: 0;
    margin-right: 10px;

    span {
      font-size: 14px;

    }
  }

  .submit {
    width: 60px;
    height: 32px;
    background: #2E5BFC;
    border-radius: 6px;
    font-family: PingFangSC;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    outline: 0;

    span {
      font-size: 14px;

    }
  }
}
</style>