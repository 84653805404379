import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import { getToken } from '@/utils/method
import { useCounterStore } from '@/store/index'
import { registerRoutes } from "@/router/dynamic";

let routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: "/index", // 重定向:重新指向其它path,会改变网址\
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/index.vue"),

      }, {
        path: "/userCenter",
        name: "userCenter",
        component: () => import("../views/Agentmodel/userCenter.vue"),

      },
      {
        path: "/msglist",
        name: "msglist",
        component: () => import("../views/msglist.vue"),
      },
      {
        path: "/agentTest",
        name: "agentTest",
        component: () => import("../views/Agentmodel/agentTest.vue"),
      },
      {
        path: "/personalCenter",
        name: "personalCenter",
        component: () => import("../views/PersonalCenter/personalCenter.vue"),
      },
      {
        path: "/personalEdit",
        name: "personalEdit",
        component: () => import("../views/PersonalCenter/personalEdit.vue"),
      }, {
        path: "/test",
        name: "test",
        component: () =>
          import("../views/Pluginmanagement/test.vue"),
      }, {
        path: "/collection",
        name: "collection",
        component: () => import("../views/PersonalCenter/collection.vue"),
      },
      {
        path: "/Executionengine",
        name: "Executionengine",
        component: () => import("../views/Executionengine/Executionengine.vue"),
      },
      {
        path: "/ExecutionengineAdd",
        name: "ExecutionengineAdd",
        component: () => import("../views/Executionengine/ExecutionengineAdd.vue"),
      },
      {
        path: "/ExecutionengineDetail",
        name: "ExecutionengineDetail",
        component: () => import("../views/Executionengine/ExecutionengineDetail.vue"),
      },
      // {
      //   path: "/PluginmanagementAdd",
      //   name: "PluginmanagementAdd",
      //   component: () =>
      //     import("../views/Pluginmanagement/PluginmanagementAdd.vue"),
      // }
      // {
      //   path: "/depletelist",
      //   name: "DepleteList",
      //   component: () => import("../views/Systemsettings/DepleteList.vue"),
      // },
      // {
      //   path: "/resource",
      //   name: "Resource",
      //   component: () => import("../views/Systemsettings/Resource.vue"),
      // },
      // {
      //   path: "/addRAG",
      //   name: "addRAG",
      //   component: () => import("../views/RAG/addRAG.vue"),
      // },
      // {
      //   path: "/KnowledgebaseAdd",
      //   name: "KnowledgebaseAdd",
      //   component: () => import("../views/Knowledgebase/KnowledgebaseAdd.vue"),
      // }
    ]
    // children: [
    //   {
    //     path: "/Agentmodel",
    //     name: "Agentmodel",
    //     component: () => import("../views/Agentmodel/Agentmodel.vue"),
    //   }, {
    //     path: "/AgentmodelAdd",
    //     name: "AgentmodelAdd",
    //     component: () => import("../views/Agentmodel/AgentmodelAdd.vue"),
    //   }, {
    //     path: "/AgentmodelDetail",
    //     name: "AgentmodelDetail",
    //     component: () => import("../views/Agentmodel/AgentmodelDetail.vue"),
    //   },
    //   {
    //     path: "/APIservices",
    //     name: "APIservices",
    //     component: () => import("../views/APIservices/APIservices.vue"),
    //   },
    //   {
    //     path: "/APIservicesAdd",
    //     name: "APIservicesAdd",
    //     component: () => import("../views/APIservices/APIservicesAdd.vue"),
    //   },
    //   {
    //     path: "/APIservicesdetail",
    //     name: "APIservicesdetail",
    //     component: () => import("../views/APIservices/APIservicesdetail.vue"),录入后端
    //   },
    //   {
    //     path: "/Executionengine",
    //     name: "Executionengine",
    //     component: () => import("../views/Executionengine/Executionengine.vue"),
    //   },
    //   {
    //     path: "/index",
    //     name: "index",
    //     component: () => import("../views/index.vue"),
    //   },
    //   {
    //     path: "/Integrateworkbenches",
    //     name: "Integrateworkbenches",
    //     component: () =>
    //       import("../views/Integrateworkbenches/Integrateworkbenches.vue"),
    //   },
    //   {
    //     path: "/IntegrateworkbenchesDetail",
    //     name: "IntegrateworkbenchesDetail",
    //     component: () =>
    //       import("../views/Integrateworkbenches/IntegrateworkbenchesDetail.vue"),
    //   },
    //   {
    //     path: "/Langchain",
    //     name: "Langchain",
    //     component: () => import("../views/Langchain/Langchain.vue"),
    //   },
    //   {
    //     path: "/Knowledgebase",
    //     name: "Knowledgebase",
    //     component: () => import("../views/Knowledgebase/Knowledgebase.vue"),
    //   },
    //   {
    //     path: "/SearchTesting",
    //     name: "SearchTesting",
    //     component: () => import("../views/Knowledgebase/SearchTesting.vue"),
    //   },
    //   {
    //     path: "/KnowledgebaseDetail",
    //     name: "KnowledgebaseDetail",
    //     component: () => import("../views/Knowledgebase/KnowledgebaseDetail.vue"),
    //   },
    //   {
    //     path: "/LangchainAdd",
    //     name: "LangchainAdd",
    //     component: () => import("../views/Langchain/LangchainAdd.vue"),
    //   },
    //   {
    //     path: "/Deploy",
    //     name: "Deploy",
    //     component: () => import("../views/Langchain/Deploy.vue"),
    //   },
    // {
    //   path: "/Pluginmanagement",
    //   name: "Pluginmanagement",
    //   component: () =>
    //     import("../views/Pluginmanagement/Pluginmanagement.vue"),
    // },{
    //   path: "/PluginmanagementAdd",
    //   name: "PluginmanagementAdd",
    //   component: () =>
    //     import("../views/Pluginmanagement/PluginmanagementAdd.vue"),
    // }
    //   {
    //     path: "/personalCenter",
    //     name: "personalCenter",
    //     component: () => import("../views/PersonalCenter/personalCenter.vue"),
    //   },
    // {
    //   path: "/RAG",
    //   name: "RAG",
    //   redirect: "/RAGServices", // 重定向:重新指向其它path,会改变网址
    //   component: () => import("../views/RAG/RAG.vue"),
    //   children: [
    //     {
    //       path: "/RAGdetail",
    //       name: "RAGdetail",
    //       component: () => import("../views/RAG/RAGdetail.vue"),
    //     }, {
    //       path: "/RAGAPI",
    //       name: "RAGAPI",
    //       component: () => import("../views/RAG/RAGAPI.vue"),
    //     }, {
    //       path: "/RAGServices",
    //       name: "RAGServices",
    //       component: () => import("../views/RAG/RAGServices.vue"),
    //     }, {
    //       path: "/RAGConfiguration",
    //       name: "RAGConfiguration",
    //       component: () => import("../views/RAG/RAGConfiguration.vue"),
    //     }
    //   ]
    // },
    //   {
    //     path: "/APIchat",
    //     name: "APIchat",
    //     component: () => import("../views/RAG/APIchat.vue"),
    //   },
    //   {
    //     path: "/personalEdit",
    //     name: "personalEdit",
    //     component: () => import("../views/PersonalCenter/personalEdit.vue"),
    //   },
    //   {
    //     path: "/msglist",
    //     name: "msglist",
    //     component: () => import("../views/msglist.vue"),
    //   },
    //   {
    //     path: "/Systemsettings",
    //     name: "Systemsettings",
    //     component: () => import("../views/Systemsettings/Systemsettings.vue"),
    //     redirect: "/user",
    //     children: [
    //       {
    //         path: "/roles",
    //         name: "roles",
    //         component: () => import("../views/Systemsettings/roles.vue"),
    //       },
    //       {
    //         path: "/user",
    //         name: "user",
    //         component: () => import("../views/Systemsettings/user.vue"),
    //       },
    //       {
    //         path: "/TimeLine",
    //         name: "TimeLine",
    //         component: () => import("../views/Systemsettings/TimeLine.vue"),
    //       }
    //     ],
    //   },
    //   {
    //     path: "/PermissionsEdit",
    //     name: "PermissionsEdit",
    //     component: () => import("../views/Systemsettings/PermissionsEdit.vue"),
    //   },
    //   {
    //     path: "/Permissions",
    //     name: "/Permissions",
    //     component: () => import("../views/Systemsettings/Permissions.vue"),
    //   },
    //   {
    //     path: "/rolesDetails",
    //     name: "rolesDetails",
    //     component: () => import("../views/Systemsettings/rolesDetails.vue"),
    //   },

    // ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login/Login.vue"),
  },
  {
    path: "/forget",
    name: "forget",
    component: () => import("../views/Login/forget.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/Login/register.vue"),
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// let flag = true
// router.beforeEach((to, from, next) => {
//   const store = useCounterStore()
//   const token = localStorage.getItem('token')
//   if (token && to.path == '/login') {
//     next(from.path)
//   } else if (!token && to.path != '/login') {
//     if (to.path == '/forget' || to.path == '/register') {
//       next()
//     } else {
//       next('/login')
//     }
//   } else {
//     if (flag && store.userRouter.length != 0) {
//       console.log(store.userRouter, '用户菜单权限数组');
//       store.userRouter.forEach(item => {
//         if (item.children && item.children.length != 0) {
//           item.children.forEach(val => {
//             console.log(val.component);
//             console.log(val.path);
//             console.log(`../views/${val.component}.vue`);
//             let objs = {
//               path: val.path,
//               name: val.name,
//               component: () => import(`../views/${val.component}.vue`),
//               meta: val.meta,
//               children: val.children
//             }
//             console.log(objs, 'objs');
//             router.addRoute('home', objs)

//           })
//         }
//         let obj = {
//           path: item.path,
//           name: item.name,
//           component: () => import(`../views/${item.component}.vue`),
//           meta: item.meta,
//           children: item.children
//         }
//         router.addRoute('home', obj)
//       })
//       flag = false
//       next({ ...to, replace: true })
//     } else {
//       next()
//     }
//   }
// })
// 防止路由无限循环
let routeFlag = false;

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (token) {
    if (routeFlag) {
      next();
    } else {
      // 注册动态路由
      registerRoutes().then((res) => {
        // console.log(res);
        routeFlag = true;
        next({ ...to, replace: true })
        // debugger
      }).catch(() => {
        // 处理异常事件
      })
    }
  } else {
    routeFlag = false;
    console.log(to.path);
    if (to.path == "/login" || to.path == "/register" || to.path == "/forget") {
      next();
    }
    else {
      next({
        path: "/login"
      });
    }
  }
});



export default router
