<template>
  <div v-show="props.isShow" :class="['com-list-box', className]" :style="style">
    <div class="list-box">
      <div v-for="(item, index) in props.data" :key="item.companyId" class="com-list-item" @click="clickCompany(item)">
        <div class="com-list-l">
          <img class="icon-team" src="../assets/img/team.png" alt="" srcset="">
          <div>
            <div class="com-name-box">
              <div>{{ item.companyName }}</div>
              <div v-show="item.owner === '1'">拥有者</div>
            </div>
            <div class="com-msg-box">
              <div>{{ item.companyResourceLimitVo.currStandardProductName }}</div>
              <div>{{ item.userCount }}成员</div>
            </div>
          </div>
        </div>
        <slot name="itemRight"></slot>
        <img v-if="props.showActive && props.curCompanyId === item.companyId" class="icon-act"
          src="../assets/img/right.png" alt="" srcset="">
      </div>
    </div>

    <div v-show="showAdd" class="add-com-box">
      <div @click="addCom">
        <el-icon :color="'#2D3748'" :size="'18px'">
          <Plus />
        </el-icon>
        <div>创建新企业</div>
      </div>
    </div>
  </div>

</template>

<script setup>
import { ref, reactive, defineEmits } from 'vue'
import { Plus } from '@element-plus/icons-vue'
const emits = defineEmits(['clickAdd', 'clickChoose'])

const props = defineProps({
  isShow: {
    type: Boolean,
    default: false
  },
  data: {
    type: Array,
    default: () => []
  },
  style: {
    type: String,
    default: ''
  },
  className: {
    type: String,
    default: ''
  },
  showAdd: {
    type: Boolean,
    default: false
  },
  showActive: {
    type: Boolean,
    default: false
  },
  curCompanyId: {
    type: Number,
    default: 0
  },
  data: {
    type: Array,
    default: () => []
  }
})

const addCom = () => {
  emits('clickAdd');
}
const clickCompany = (o) => {
  emits('clickChoose', o)
}


</script>

<style scoped lang='scss'>
.icon-team {
  width: 32px;
  height: 32px;
}

.com-list-box {
  min-width: 320px;
  background: #FFF;
  
  .list-box {
    max-height: 400px;
    overflow-y: scroll;
  }

  .com-list-item,
  .add-com-box {
    width: 100%;
    // height: 0.75rem;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .com-name-box,
    .com-msg-box {
      display: flex;
    }

    .com-list-l {
      display: flex;

      >div {
        margin: 0 12px;
      }
    }

    .com-name-box {
      div:nth-of-type(1) {
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      div:nth-of-type(2) {
        background: rgba(63, 105, 255, 0.1);
        border-radius: 2px;
        border: 1px solid #2E5BFC;
        min-width: 44px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        color: #2E5BFC;
        font-size: 12px;
        margin-left: 8px;
      }
    }

    .com-msg-box {
      flex-shrink: 0;
      font-size: 12px;
      color: #718096;

      div {
        margin-right: 12px;
      }
    }

    .icon-act {
      width: 20px;
      height: 20px;
    }
  }

  .com-list-item:hover {
    background: #F0F2FA;
  }

  .add-com-box {
    border-top: 1px solid #F0F2FA;

    >div {
      display: flex;
      align-items: center;

      div {
        margin-left: 25.6px;
      }
    }
  }
}
</style>