<template>
  <div v-show="props.modelValue" class="mask-box">
    <div v-show="showPayType === 'product'" class="pay-box">
      <img class="icon-hg" src="../../assets/img/hg.png" alt="" srcset="">

      <div class="pay-header">
        <div class="pay-title">
          <div>服务订阅</div>
          <div @click="handleClose">
            <el-icon :color="'#2D3748'" :size="'20px'">
              <Close />
            </el-icon>
          </div>
        </div>
        <div class="tab-box">
          <div :class="[packageAct === 0 ? 'act1' : 'def1']" @click="changePackage(0)"><span>套餐</span></div>
          <div :class="[packageAct === 1 ? 'act2' : 'def2']" @click="changePackage(1)"><span>资源包</span></div>
        </div>
      </div>
      <!-- 套餐 -->
      <div v-show="packageAct === 0" class="pay-content">
        <div class="content-l">
          <div :class="['content-l-title', standardAct === 1 ? 'qj' : null]">
            <img src="../../assets/img/icon-bb.png" alt="" srcset="">
            {{ DATA.standarPackage[standardAct]?.productName }}
          </div>
          <div class="com-l-des">
            <div class="con-l-des-item">{{ `${DATA.standarPackage[standardAct]?.memberLimit !== (-1 && null) ?
              DATA.standarPackage[standardAct]?.memberLimit + '个企业成员' : '企业成员无限制'}` }}</div>
            <div class="con-l-des-item">{{ `${DATA.standarPackage[standardAct]?.memberLimit !== (-1 && null) ?
              DATA.standarPackage[standardAct]?.ragLimit
              + '个应用' : '应用无限制'}` }}</div>

            <div class="con-l-des-item">{{ `${DATA.standarPackage[standardAct]?.agentLimit !== (-1 && null) ?
              DATA.standarPackage[standardAct]?.agentLimit + '个智能体' : '智能体无限制'}` }}</div>
            <div class="con-l-des-item">{{ `${DATA.standarPackage[standardAct]?.pluginLimit !== (-1 && null) ?
              DATA.standarPackage[standardAct]?.pluginLimit + '个自定义插件' : '自定义插件无限制'}` }}</div>
            <div class="con-l-des-item">{{ DATA.standarPackage[standardAct]?.knowledgeLibraryLimit }}万字知识库容量</div>
            <div class="con-l-des-item">{{ DATA.standarPackage[standardAct]?.bigModelQuota }}元模型调用额度/月</div>
            <div class="con-l-des-item">对话历史保存{{ DATA.standarPackage[standardAct]?.chatHistoryDays }}天</div>

            <div class="btn-version-box">
              <div @mouseenter="handleVersion(true)" @mouseleave="handleVersion(false)">查看版本对比 > </div>
            </div>
          </div>

        </div>
        <div v-show="isShowVersion" class="content-version">
          <pay-version></pay-version>
        </div>
        <div v-show="!isShowVersion" class="content-m">
          <div class="content-m-t">
            <div class="box-title">当前企业</div>
            <div>
              <div>{{ props.company.companyName }}</div>
              <div :class="['content-cur',
                props.company.companyResourceLimitVo?.productVersionCode === 2 ? 'zy' : null,
                props.company.companyResourceLimitVo?.productVersionCode === 3 ? 'qj' : null,
              ]">
                <img v-show="props.company.companyResourceLimitVo?.productVersionCode === 1"
                  src="../../assets/img/pay/icon-mf.png" alt="" srcset="">
                <img v-show="props.company.companyResourceLimitVo?.productVersionCode === 2"
                  src="../../assets/img/pay/icon-zy.png" alt="" srcset="">
                <img v-show="props.company.companyResourceLimitVo?.productVersionCode === 3"
                  src="../../assets/img/pay/icon-qj.png" alt="" srcset="">
                <div>{{ props.company.companyResourceLimitVo?.currStandardProductName }}</div>
              </div>
            </div>

            <!-- <el-input placeholder="请输入企业名称"></el-input> -->


          </div>
          <div class="content-m-m">
            <div class="box-title">选择版本</div>
            <div class="content-m-m-c">
              <div v-for="(item, i) in DATA.standarPackage" @click="changeType(i, 'standard')" :key="item.productId"
                :class="['card-box', standardAct == i ? 'act' : '']">
                <div class="card-title">{{ item.productName }}</div>
                <div class="total"> <span>¥ {{ item.salePrice }}/月</span></div>
                <div class="des">{{ item.memberLimit !== (-1 && null) ? `${item.memberLimit}个企业成员` : '企业成员无限制' }}</div>
              </div>
            </div>
          </div>
          <div class="content-m-b">
            <div class="box-title">购买时长</div>
            <div class="time-box">
              <div v-for="(item, i) in DATA.purchaseDurations" @click="changeTime(i)"
                :class="['card-box', timeAct == i ? 'act' : '']">
                <div class="card-title">{{ item.displayName }}</div>
                <div v-show="item.tag" class="tag">{{ item.tag }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="!isShowVersion" class="content-r">
          <div class="content-r-t">
            <div class="box-title">订单详情</div>
            <div class="com-box">
              <div>当前企业：</div>
              <div>{{ props.company.companyName }}</div>
            </div>
            <div>
              <div>订单类型：</div>
              <div>{{ DATA.standarPackage[standardAct]?.productTypeName }}</div>
            </div>
            <div>
              <div>订购版本：</div>
              <div>{{ DATA.standarPackage[standardAct]?.productName }}</div>
            </div>
            <div>
              <div>购买时长：</div>
              <div>{{ DATA.purchaseDurations[timeAct]?.displayName }}</div>
            </div>
            <div>
              <div>到期时间：</div>
              <div>{{ DATA.purchaseDurations[timeAct]?.expDate }}</div>
            </div>
          </div>
          <div class="content-r-b">
            <div class="order-box">
              <div>订单金额</div>
              <div class="total">￥<span>{{ DATA.standarPackage[standardAct]?.purchaseDurations[timeAct]?.durationPrice
                  }}</span></div>
            </div>
            <div class="btn-box" @click="handleCreateOrder">提交订单</div>
          </div>
        </div>
      </div>

      <!-- 资源包 -->
      <div v-show="packageAct === 1" class="pay-content">
        <div class="content-l">
          <!-- 模型调用 -->
          <div v-show="resourceAct === 0" class="com-l-des">
            <div class="box-tiitle resource-model-title">模型调用</div>
            <div class="resource-sec-title">模型调用按照模型厂商公开价格计费</div>
            <div class="box-title resource-knowledge-title">支持模型</div>
            <div v-for="(item, i) in modelDes" class="resource-type-item">
              <img src="../../assets/img/pay/right.png" alt="" srcset="">
              <div>{{ item }}</div>
            </div>
          </div>
          <!-- 知识库介绍 -->
          <div v-show="resourceAct === 1" class="com-l-des">
            <div class="box-tiitle resource-box-title">知识库</div>
            <div class="resource-sec-title">自主配置企业知识、行业知识</div>
            <div class="box-title resource-knowledge-title">知识库文件支持格式</div>
            <div v-for="(item, i) in knowledgeDes" class="resource-type-item">
              <img :src="require(`@/assets/img/pay/filetype${i + 1}.png`)" alt="" srcset="">
              <div>{{ item }}</div>
            </div>
          </div>
        </div>
        <div>
        </div>
        <div class="content-m">
          <div class="content-m-t">
            <div class="box-title">当前企业</div>
            <div>
              <div>{{ props.company.companyName }}</div>
              <div class="resource-now line-l"><span>模型调用额度：</span>{{ userInfo.product.usedBigModelQuota > 0 ?
                Math.ceil(userInfo.product.usedBigModelQuota * 10000) / 10000 : 0 }}元 /
                {{ userInfo.product.totalBigModelQuotaLimit }}元</div>
              <div class="resource-now"><span>知识库容量：</span>{{ userInfo.product.usedKnowledgeLibraryStr }} / {{
                userInfo.product.totalKnowledgeLibraryLimit }}万字</div>
            </div>
          </div>
          <div class="content-m-m">
            <div class="box-title">选择资源包</div>
            <div class="content-m-m-c">
              <div v-for="(item, i) in DATA.resourcePackage" @click="changeType(i, 'resource')" :key="item.productId"
                :class="['card-box', resourceAct == i ? 'act' : '']">
                <div class="card-title">{{ item.productName }}</div>
                <div class="total"> <span>¥ {{ item.salePrice }}</span></div>
              </div>
            </div>
          </div>
          <div class="content-m-b">
            <div class="box-title">有效期</div>
            <div class="time-box">
              <div v-for="(item, i) in DATA.resourcePur" :class="['card-box', resourceTimeAct == i ? 'act' : '']">
                <div class="card-title">{{ item.displayName }}</div>
              </div>
            </div>
          </div>
          <div class="content-m-b">
            <div class="box-title">购买数量</div>
            <div>
              <el-input-number v-model="resourceNum" :min="resourceMinNum" />
            </div>
          </div>
        </div>
        <div class="content-r">
          <div class="content-r-t">
            <div class="box-title">订单详情</div>
            <div class="com-box">
              <div>当前企业：</div>
              <div>{{ props.company.companyName }}</div>
            </div>
            <div>
              <div>订单类型：</div>
              <div>{{ DATA.resourcePackage[resourceAct]?.productTypeName }}</div>
            </div>
            <div>
              <div>资源包产品：</div>
              <div>{{ DATA.resourcePackage[resourceAct]?.productName }}</div>
            </div>
            <div>
              <div>购买总量：</div>
              <div>
                {{
                  resourceAct === 0 ?
                    resourceTotal + '元' :
                    parseInt(DATA.resourcePackage[resourceAct]?.productName) * resourceNum + '万字'
                }}
              </div>
            </div>
            <div>
              <div>到期时间：</div>
              <div>{{ DATA.resourcePur[resourceTimeAct]?.expDate }}</div>
            </div>
          </div>
          <div class="content-r-b">
            <div class="order-box">
              <div>订单金额</div>
              <div class="total">￥<span>{{ resourceTotal }}</span></div>
            </div>
            <div class="btn-box" @click="handleCreateOrder">提交订单</div>
          </div>
        </div>
      </div>
    </div>


    <Paying v-if="showPayType === 'paying'" :data="DATA.payingData" @close="closePaying" @result="handleResult">
    </Paying>
    <pay-result v-show="showPayType === 'result'" :data="DATA.resultData" @close="closeResult"></pay-result>
  </div>



</template>

<script setup>
import { storeToRefs } from 'pinia'
import { ref, reactive, computed, defineProps, defineEmits, onMounted, watch } from 'vue'
import { Close } from '@element-plus/icons-vue'
import { getProducs, createOrder } from '@/api/pay/index'
import PayVersion from './components/PayVersion.vue'
import Paying from './components/Paying.vue'
import PayResult from './components/PayResult.vue'
import { useUserStore } from '@/store/user'
import router from '@/router'
const userStore = useUserStore();

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
    require: true
  },
  company: {
    type: Object,
    default: () => { }
  },
  initType: {
    type: String,
    default: 'product'
  }
})
const { userInfo } = storeToRefs(userStore);
const emits = defineEmits(['update:modelValue'])

const showPayType = ref('product');
const packageAct = ref(0);//套餐、资源包标记
const standardAct = ref(0);//套餐版本标记
const timeAct = ref(0);//套餐时长标记
const resourceAct = ref(0);//资源包标记
const resourceTimeAct = ref(0);//资源包时长标记
const resourceNum = ref(1);//资源包个数
const resourceMinNum = ref(1);
const DATA = reactive({
  packageData: [],
  standarPackage: [],//套餐数据
  purchaseDurations: [],//套餐时长数据
  resourcePackage: [],
  resourcePur: [],
  payingData: {}, //支付相关信息
  resultData: {}, //支付结果相关信息
})
const resourceTotal = computed(() => {
  return resourceNum.value * DATA.resourcePur[resourceTimeAct.value]?.durationPrice || '';
})
const knowledgeDes = reactive([
  'Word',
  'PPT',
  'PDF',
  'Excel',
  'Txt',
  '网页链接',
  '自定义文本'
])
const modelDes = reactive([
  'GEB系列模型',
  'GLM-4',
  'GLM-3-Turbo',
  //'ERNIE-3.5-8K',
  //'ERNIE-4.0-8K',
  //'moonshot-v1-32k',
])
const isShowVersion = ref(false);

const changePackage = n => {
  packageAct.value = n;

}
const changeType = (n, t) => {
  switch (t) {
    case 'standard':
      standardAct.value = n;
      DATA.purchaseDurations = DATA.standarPackage[n].purchaseDurations;
      break;

    case 'resource':
      resourceNum.value = 1;
      resourceAct.value = n;
      DATA.resourcePur = DATA.resourcePackage[n].purchaseDurations;
  }

}

// 时长切换
const changeTime = n => {
  timeAct.value = n;
}

// 获取产品
const getProducsList = async () => {
  const res = await getProducs();
  let data = res.data;
  DATA.packageData = data;
  DATA.standarPackage = data.STANDARD_PACKAGE.filter(v => {
    if (v.productId !== 1) {
      return v;
    };
  });
  DATA.purchaseDurations = DATA.standarPackage[0].purchaseDurations;
  DATA.resourcePackage = data.RESOURCE_PACKAGE;
  DATA.resourcePur = DATA.resourcePackage[0].purchaseDurations;
  standardAct.value = 0;
}

const handleVersion = (f) => {
  isShowVersion.value = f;
}
// 提交订单
const getData = () => {
  return {
    companyId: props.company.companyId,
    companyName: props.company.companyName,
    orderType: packageAct.value + 1, //1套餐 2资源包
    productType: packageAct.value === 0 ? DATA.standarPackage[standardAct.value]?.productVersionCode : null,//套餐类型1免费2专业3旗舰
    durationName: packageAct.value === 0 ? DATA.purchaseDurations[timeAct.value]?.durationKey : DATA.resourcePur[resourceTimeAct.value]?.durationKey,//时长
    expireTime: packageAct.value === 0 ? DATA.purchaseDurations[timeAct.value]?.expDate : DATA.resourcePur[resourceTimeAct.value]?.expDate,//到期时间
    productId: packageAct.value === 0 ? DATA.standarPackage[standardAct.value]?.productId : DATA.resourcePackage[resourceAct.value]?.productId,//产品id
    shouldAmount: packageAct.value === 0 ? DATA.purchaseDurations[timeAct.value]?.durationPrice : DATA.resourcePur[resourceTimeAct.value]?.durationPrice * resourceNum.value,//应付费用
    resourcePackageNum: packageAct.value === 1 ? resourceNum.value : null,//资源包数量
    price: packageAct.value === 0 ? DATA.standarPackage[standardAct.value]?.salePrice : DATA.resourcePackage[resourceAct.value]?.salePrice
  }
}
// 提交订单
const handleCreateOrder = async () => {
  let dt = getData();
  let { code, data } = await createOrder(dt);
  if (code === 200) {
    DATA.payingData = {
      companyName: props.company.companyName,
      packageType: packageAct.value + 1, //1套餐 2资源包
      ...data
    };
    showPayType.value = 'paying';
  }
}

// 关闭套餐、资源包页面
const handleClose = () => {
  let flag = !props.modelValue;
  init();
  emits('update:modelValue', flag);
}

// 关闭支付二维码页面
const closePaying = (isNext) => {
  if (isNext) {
    showPayType.value = 'result'
  } else {
    handleClose();
    showPayType.value = 'product';
    init();
  }
}

// 支付结果
const handleResult = (o) => {
  DATA.resultData = o;
  showPayType.value = 'result';
}
// 关闭结果页
const closeResult = (p, par) => {
  if (p) {
    if (p.includes('/')) {
      handleClose();
      router.push({
        path: p,
        query: {
          type: par
        }
      })
    } else {
      init();
    }
  } else {
    handleClose();
    init();
  }
  showPayType.value = 'product';
}

const init = () => {
  packageAct.value = 0;
  standardAct.value = 0;
  timeAct.value = 0;
  resourceAct.value = 0;
  resourceTimeAct.value = 0;
  resourceNum.value = 1;
}


onMounted(() => {
  getProducsList();
})

</script>

<style scoped lang='scss'>
$width: 1200px;
$color: #2E5BFC;
$color2: #718096;

.mask-box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2D3748;
}

.total {
  color: #EA4F3E;

  span {
    font-size: 24px;
    font-weight: bold;
  }
}

.card-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #F0F2FA;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  margin-bottom: 24px;
  border: 2px solid #FFF;

  .card-title {
    font-weight: 500;
    font-size: 16px;
    color: #202332;
  }

  .des {
    width: 100%;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: $color2;
  }
}

.card-box.act {
  background: linear-gradient(131deg, #EBEFFF 0%, #F7F9FF 100%);
  border: 2px solid $color;

  .card-title {
    color: $color;
  }

  .des {
    background: $color;
    color: #FFF;
  }

  &::after {
    content: '';
    width: 32px;
    height: 32px;
    background: url('~@/assets/img/icon-bgr.png') no-repeat center;
    background-size: 100%;
    position: absolute;
    right: -2px;
    top: -2px;
  }
}

.pay-box {
  width: $width;
  font-size: 14px;
  box-sizing: border-box;
  position: relative;

  .icon-hg {
    width: 200px;
    height: 200px;
    position: absolute;
    right: 80px;
    top: -80px;
  }
}

.pay-header {
  background: linear-gradient(270deg, #C69E87 0%, #EEDBC9 100%);
  border-radius: 20px 20px 0px 0px;
}

.pay-title {
  height: 56px;
  padding: 16px 40px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab-box {
  height: 70px;
  font-size: 16px;
  position: relative;
  cursor: pointer;

  div {
    width: 636px;
    text-align: center;
    line-height: 70px;
    position: absolute;
    top: 0;
  }

  div:nth-of-type(1) {
    left: 0;
  }

  div:nth-of-type(2) {
    right: 0;
  }

  .act1,
  .act2 {
    z-index: 1;

    span {
      position: relative;
      font-weight: 500;

      &::after {
        content: "";
        width: 100%;
        height: 4px;
        border-radius: 2px;
        background: #DFC4B0;
        position: absolute;
        bottom: -12px;
        left: 50%;
        margin-left: -50%;
      }
    }
  }

  .def1 {
    color: #E0CCB0;
    background: url('~@/assets/img/def1.png') no-repeat left;
    background-size: 100%;

    span {
      position: relative;
      top: 8px;
    }
  }

  .act1 {
    color: #000000;
    background: url('~@/assets/img/act1.png') no-repeat;
    background-position: 0px 4px;
    background-size: 100%;
  }

  .def2 {
    color: #E0CCB0;
    background: url('~@/assets/img/def2.png') no-repeat right;
    background-size: 100%;

    span {
      position: relative;
      top: 8px;
    }
  }

  .act2 {
    color: #000000;
    background: url('~@/assets/img/act2.png') no-repeat;
    background-position: 0px 4px;
    background-size: 100%;
  }
}

.pay-content {
  width: $width;
  // min-height: 6.25rem;
  min-height: 528px;
  box-sizing: border-box;
  background: #F5F6F9;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  justify-content: space-between;
}

.content-l,
.content-m,
.content-r,
.content-version {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.box-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
}

.content-version {
  flex-grow: 1
}

.content-l {
  width: 260px;
  flex-shrink: 0;

  .content-l-title {
    height: 40px;
    color: #653420;
    font-size: 16px;
    background: linear-gradient(270deg, #C69E87 0%, #EEDBC9 100%);
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }

  .content-l-title.qj {
    color: #F5CE90;
    background: linear-gradient(330deg, #282E38 0%, #3A3F48 100%);
  }

  .com-l-des {
    padding: 12px 20px;
    background: #FFF;
    flex-grow: 1;
    border-radius: 8px;
    position: relative;

    .con-l-des-item {
      padding: 6px 0;
    }

    .btn-version-box {
      width: 100%;
      color: #202332;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: 20px;
      cursor: pointer;
    }
  }

  .resource-box-title {
    background: url('~@/assets/img/pay/icon-kn.png') no-repeat left;
    background-size: 24px 24px;
    text-indent: 28px;
  }

  .resource-model-title {
    background: url('~@/assets/img/pay/icon-md.png') no-repeat left;
    background-size: 24px 24px;
    text-indent: 28px;
  }

  .resource-sec-title {
    color: $color2;
    margin-top: 12px;
  }

  .resource-knowledge-title {
    margin-top: 40px;
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background: #F0F2FA;
      position: absolute;
      top: -20px;
      left: 0
    }
  }

  .resource-type-item {
    display: flex;
    align-items: center;
    margin-top: 16px;

    img {
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }
  }
}

.content-m {
  width: 600px;
  position: relative;
  flex-grow: 1;

  .content-m-t {

    div:nth-of-type(2) {
      display: flex;

      .content-cur {
        width: 67px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 4px;

        img {
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }

        font-size: 12px;
        background: #ECEDF0;
        border-radius: 11px;
        border: 1px solid #DDE0EB;

        &.zy {
          background: #FFFBF8;
          border: 1px solid #FAEADE;
        }

        &.qj {
          color: #F5CE90;
          background: linear-gradient(330deg, #282E38 0%, #3A3F48 100%);
          border: 1px solid rgba(250, 234, 222, 0.13);
        }
      }
    }

    .resource-now {
      margin-left: 8px;
      position: relative;

      span {
        color: $color2;
      }
    }

    .line-l {
      margin-left: 16px;

      &::before {
        content: '';
        width: 1px;
        height: 12px;
        background: $color2;
        position: absolute;
        top: 4px;
        left: -8px;
      }
    }
  }

  .content-m-m {
    .box-title {
      margin-top: 25px;
    }

    .content-m-m-c {
      display: flex;
      flex-wrap: wrap;

    }

    .card-box {
      width: 200px;

      .card-title {
        height: 32px;
        line-height: 32px;
      }

      .total {
        height: 48px;
        line-height: 48px;
      }
    }
  }

  .content-m-b {
    .box-title {
      margin-top: 25px;
    }


    .time-box {
      display: flex;

      .card-box {
        width: 168px;
        height: 72px;
        line-height: 72px;
        text-align: center;
        position: relative;

        .tag {
          width: 86px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          background: url('!@/assets/img/pay/tag.png') no-repeat left;
          background-size: 100%;
          position: absolute;
          top: -2px;
          left: -3px;
          z-index: 1;
          font-size: 13px;
          color: #FFF;
        }
      }

      .fav {
        width: 80px;
        height: 24px;
        background: url('~@/assets/img/icon-bgf.png') no-repeat left;
        background-size: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

}

.content-r {
  width: 275px;
  flex-shrink: 0;
  border-left: 1px solid #E2E5EF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content-r-t {

    >div {
      display: flex;
      margin-top: 12px;
    }

    .com-box {
      div:nth-of-type(1) {
        flex-shrink: 0;
      }

      div:nth-of-type(2) {
        min-width: 20%;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
      }
    }
  }

  .order-box {
    width: 100%;
    height: 60px;
    background: #ECEDF0;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: 1px solid #DDE0EB;
    margin-bottom: 20px;
  }

  .btn-box {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color;
    border-radius: 8px;
    color: #FFF;
    cursor: pointer;
  }
}
</style>