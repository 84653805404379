import request from "@/utils/request";

export const postLogin=(data)=>request.post('/login',data)

export const getInfo=()=>request.get('/getInfo')
// 查询系统消息列表
// GET
// /wd/msg/list
export const getMsgList=()=>request.get('/wd/msg/list')


// /wd/msg/delete/434 
// 删除信息
export const deleteMsg=(id)=>request.delete(`/wd/msg/delete/${id}`)

// /wd/msg/readMsg
// 已读系统消息列表
export const getReadMsgList=()=>request.get('/wd/msg/readMsg')

// 
export const getMsgRead=(id)=>request.get(`/wd/msg/read/${id}`)

// 切换企业
export const switchLogin = (data) => request.post('/switch/login', data)
