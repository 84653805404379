import { ref, reactive } from 'vue'
import { switchLogin } from '@/api/login/login'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store/user'
// const router = useRouter();

// 切换企业
export const checkCompany = async (data) => {
  const userStore = useUserStore();
  const { code, token, info } = await switchLogin({ userId: data.userId, username: data.userName });
  if(code === 200) {
    userStore.setUserInfo(info);
    localStorage.setItem('token', JSON.stringify(`Bearer ${token}`));
    localStorage.setItem('userInfo', JSON.stringify(info));
    return Promise.resolve();
  } else {
    localStorage.removeItem('token');
    router.push({
      path: '/login'
    })
  }
}