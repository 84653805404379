<template>

  <div class="result-box">
    <div class="header">
      <div>支付结果</div>
      <el-icon :color="'#000'" :size="'16px'" @click="handleClose('', '')">
        <Close />
      </el-icon>
    </div>
    <div class="content">

      <div>
        <img v-show="props.data.type === 'SUCCESS'" class="icon" src="../../../assets/img/pay/success.png" alt=""
          srcset="">
        <img v-show="props.data.type === 'PAYERROR'" class="icon" src="../../../assets/img/pay/error.png" alt=""
          srcset="">
        <img v-show="props.data.type === 'OTHER'" class="icon" src="../../../assets/img/pay/undefined.png" alt=""
          srcset="">
      </div>
      <div v-show="props.data.type === 'SUCCESS'" class="result">
        <div>支付成功</div>
      </div>
      <div v-show="props.data.type === 'SUCCESS'" class="total">
        实付款<span>￥{{ props.data.payAmount }}</span>
      </div>

      <div class="warn">
        <div v-show="props.data.type === 'PAYERROR'">支付遇到问题，请尝试重新购买</div>
        <div v-show="props.data.type === 'OTHER'">暂时查询不到支付结果，若您已完成支付，请稍后在购买记录中查看</div>
      </div>

      <div>
        <div v-show="['OTHER', 'SUCCESS'].includes(props.data.type)">
          <el-button @click="handleClose('/paylist', props.data.orderType)" style="width:116px;height:40px;font-size: 14px;">查看购买结果</el-button>
        </div>
        <div v-show="['OTHER', 'SUCCESS'].includes(props.data.type)">
          <el-button @click="handleClose('', '')" type="primary"
            style="width:60px;height:40px;font-size: 14px;background: #2E5BFC;color:#FFF;">确定</el-button>
        </div>
        <div v-show="['PAYERROR'].includes(props.data.type)">
          <el-button @click="handleClose('', '')" style="width:76px;height:40px;font-size: 14px;">关闭</el-button>
        </div>
        <div v-show="['PAYERROR'].includes(props.data.type)">
          <el-button @click="handleClose('product', '')" type="primary"
            style="width:130px;height:40px;font-size: 14px;background: #2E5BFC;color:#FFF;">返回购买页</el-button>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import router from '@/router';
import { Close } from '@element-plus/icons-vue'
import { ref, reactive, defineProps, defineEmits } from 'vue'
const props = defineProps({
  data: {
    type: Object,
    default: () => { }
  }
})
const emits = defineEmits(['close'])
const handleClose = (p, par) => {
  emits('close', p, par);
}
</script>

<style scoped lang='scss'>
.result-box {
  width: 564px;
  height: 400px;
  background: #FFF;
  padding: 34px 30px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;

  div {
    display: flex;
    justify-content: center;
    padding: 0 8px;
  }

  .icon {
    width: 160px;
    height: 160px;
  }
}

.result {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
}

.total {
  color: #202332;
  font-size: 16px;

  span {
    color: #EA4F3E;
    margin-left: 8px;
  }
}

.warn {
  div {
    padding: 4px 12px 4px 28px;
    background: #FFFBE6 url('~@/assets/img/pay/warn.png') no-repeat 8px;
    background-size: 12px;
    border: 1px solid #FFF1B8;
    border-radius: 4px;
    font-size: 12px;
  }
}
</style>