<template>

  <div class="paying-box">
    <div class="paying-header">
      <div class="paying-title">
        <div>订单支付</div>
        <div @click="handleClose(false)">
          <el-icon :color="'#2D3748'" :size="'20px'">
            <Close />
          </el-icon>
        </div>
      </div>
    </div>
    <div class="paying-content">
      <div class="paying-msg-box">
        <div>
          <div>当前企业：{{ props.data.companyName }}</div>
          <div v-show="props.data.packageType===1">订购版本：{{ props.data.productType }}</div>
          <div v-show="props.data.packageType===2">资源包：{{ props.data.productType }}</div>
          <div>到期时间：{{ props.data.expireTime }}</div>
        </div>
        <div>
          <div>订单类型：{{ props.data.orderType }}</div>
          <div v-show="props.data.packageType===1">购买时长：{{ props.data.duration }}</div>
          <div v-show="props.data.packageType===2">购买总量：{{ props.data.resourceCountDesc }}</div>
          <div>订单编号：{{ props.data.orderCode }}</div>
        </div>
        <div>
          <div>应付金额：<span class="total">¥{{ props.data.shouldAmount }}</span></div>
        </div>
      </div>

      <div class="code-box">
        <vue-qrcode v-if="props.data.codeUrl" :value="props.data.codeUrl"></vue-qrcode>
        <div class="text">微信扫码支付</div>
        <!-- <div class="btn-box">
          <el-button @click="handleClose(true)"
            style="background-color: #2E5BFC;color: #fff; width: 3.425rem; height: .5rem;border-radius: 8px;">已完成支付</el-button>
        </div> -->
      </div>
    </div>
  </div>

</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, onMounted } from 'vue'
import VueQrcode from 'vue-qrcode'
import { Close } from '@element-plus/icons-vue'
import { getOrderStatus } from '@/api/pay/index'
import { getInfo } from '@/api/login/login'
import { useUserStore } from '@/store/user'
const userStore = useUserStore();

const emits = defineEmits(['close', 'result'])
const props = defineProps({
  data: {
    type: Object,
    default: () => { }
  }
})

const timer = ref();
const handleClose = (isNext) => {
  clearInterval(timer.value);
  setTimeout(()=>{
    emits('close', isNext);
  },0)
}

const getResult = async () => {
  let { data } = await getOrderStatus(props.data.orderCode);
  switch (data.payStatus) {
    case 'NOPAY':
      break;
    case 'SUCCESS':
      clearInterval(timer.value);
      getInfo().then( res => {
        if( res.code === 200 ) {
          localStorage.setItem('userInfo', JSON.stringify(res));
          userStore.setUserInfo(res);
        }
      })
      handleResult('SUCCESS', data);
      break;
    case 'PAYERROR':
      clearInterval(timer.value);
      handleResult('PAYERROR');
      break;
    case 'OTHER':
      clearInterval(timer.value);
      handleResult('OTHER')
      break;
    default:
      break;
  }
}

const handleResult = (type, data) => {
  emits('result', {
    type,
    ...data
  })
}

onMounted(() => {
  timer.value = setInterval(() => {
    getResult()
  }, 3000)
})
</script>

<style scoped lang='scss'>
.paying-box {
  width: 640px;
  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 14px;
}

.paying-header {
  background: linear-gradient(270deg, #C69E87 0%, #EEDBC9 100%);

}

.paying-title {
  height: 56px;
  padding: 16px 40px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.paying-content {
  width: 100%;
  // height: 6.1rem;
  height: 400px;
  padding: 24px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  background: #F5F6F9;
}

.paying-msg-box {
  width: 100%;
  height:124px;
  padding: 20px;
  border-radius: 8px;
  background: #ECEDF0;
  border: 1px solid #DDE0EB;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  >div {
    height: 33%;
    display: flex;

    div:nth-of-type(1) {
      width: 30%;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    div:nth-of-type(2) {
      width: 30%;
    }

    div:nth-of-type(3) {
      width: 30%;
    }
  }

  >div:nth-of-type(2) {
    padding-top: 6px;
  }

  .total {
    font-size: 22px;
    color: #EA4F3E !important;
  }
}

.code-box {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  img {
    width: 160px;
    height: 160px;
  }

  .text {
    // width: 1.375rem;
    height: 20px;
    background: url('~@/assets/img/pay/icon-wx.png') no-repeat left;
    background-size: 24px 20px;
    text-indent: 28px;
  }


}
</style>