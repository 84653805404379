<template>
  <router-view class="app" />
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { getInfo } from '@/api/login/login'
import { useUserStore } from './store/user';
const userStore = useUserStore();
const token = JSON.parse(localStorage.getItem('token'))
// 定义一个防抖函数
const debounce = (callback, delay) => {
  let tid
  return function (...args) {
    const ctx = this
    tid && clearTimeout(tid)
    tid = setTimeout(() => {
      callback.apply(ctx, args)
    }, delay)
  }
}

// 获取用户相关信息
const handleGetInfo = () => {
  getInfo().then(res => {
    if (res.code == 200) {
      localStorage.setItem('userInfo', JSON.stringify(res));
      userStore.setUserInfo(res);
    }
  })
}

// 改写 ResizeObserver 的构造函数
onMounted(() => {
  if (token) {
    handleGetInfo();
  }
  const originalResizeObserver = (window).ResizeObserver;
  (window).ResizeObserver = class ResizeObserver extends originalResizeObserver {
    constructor(callback) {
      callback = debounce(callback, 20)
      super(callback)
    }
  }
});

</script>


<style lang="scss">
@import url('./assets/css/index.css');

.el-item-i-SystemSetting .el-radio__input.is-checked+.el-radio__label {
  color: #000 !important;
}

.el-radio__input.is-checked+.el-radio__label {
  color: #000 !important;
}

.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
  color: #3e6afe !important;
  font-weight: 700;
}

.el-button {
  --el-button-hover-text-color: '' !important;
  // --el-button-hover-border-color: '' !important;
}

.el-checkbox {

  --el-checkbox-checked-bg-color: #2E5BFC !important;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;

  input::placeholder {
    font-size: 14px !important;
    color: #718096;
  }
}

.el-pager li.is-active {
  color: #2E5BFC !important;
  cursor: default;
  font-weight: 700;
}

html,
body,
#app,
.app {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: PingFangSC !important;
}


// html {
//   @media screen and (min-width: 1200px) {
//     font-size: 50px;
//   }

//   @media screen and (max-width: 1920px) {
//     font-size: 80px;
//   }
// }

.el-text.el-text--primary {
  --el-text-color: #2E5BFC !important;
}

.el-table th.el-table__cell {
  background-color: #F6F7FB !important;
  font-size: 12px;
}

// @media screen and (max-width: 1440px) {
//   html {
//     font-size: 60px !important;
//     max-width: 1920px;
//     min-width: 1024px;
//   }
// }

// @media screen and (min-width: 1920px) {
//   html {
//     max-width: 1920px;
//     min-width: 1024px;
//     font-size: 80px !important;
//   }
// }
</style>
