// fun 需要防抖节流的函数，wait事件 注意this指向 
// export function Stabilization(timeout, fn) {
//     // 防抖
//     let _time = null
//     return function () {
//         let _arg = arguments
//         clearTimeout(_time)
//         _time = setTimeout(() => {
//             fn.apply(this, _arg)
//         }, timeout)
//     }
// }
// // fun 需要防抖节流的函数，wait事件 注意this指向 
// export function Throttling(timeout, fn) {
//     // 节流
//     let _playing = null
//     return function () {
//         let _arg = arguments
//         if (_playing) {
//             return
//         }
//         _playing = true
//         setTimeout(() => {
//             fn.apply(this, _arg)
//             _playing = false
//         }, timeout)
//     }
// }

export function getToken() {
    return JSON.parse(localStorage.getItem('token'))
    // return 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImQyMDQwMGY2LWE1NDQtNDk0Zi05M2NmLWRjOTU2YmIxNmM0NCJ9.mLjIBJOW2XmWcchgzqlYb3NpeYV2zOzNCH9ueaSmGpkys2Z1Ljor2W3891i5ZZKDmVimgQUDY6XyxL-fS9QHyA'
}

import { useCounterStore } from "@/store";


export function btnPerms(app) {
    
    app.directive('has', {
        mounted(el, binding) {
            if (!permsJudge(binding.value)) {
                console.log(binding,'binding');
                el.parentNode.removeChild(el);
            }
            function permsJudge(value) {
                let perms =JSON.parse(localStorage.getItem('userInfo')).permissions
                // console.log(perms);
                for (let item of perms) {
                    if (item === value) {
                        return true;
                    }
                }
                return false;
            }
        }
    });
}
