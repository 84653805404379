import { defineStore } from 'pinia'
export const useCounterStore = defineStore('counter', {
  state: () => ({
    count: 0,
    userRouter: [],
    userInfo:{}
  }),
  getters: {
    
  },
  actions: {
    increment() {
      this.count++
    },
  },
  persist: true
})  