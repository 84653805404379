///system/user/userList
import request from "@/utils/request";

export const getUserList = (pageNum, pageSize) =>
  request.get(`/system/user/list?pageNum=${pageNum}&pageSize=${pageSize}`);


export const putUser = (data) =>
  request.put(`/system/user/edit`, data);

export const delUser = (data) =>
  request.delete(`/system/user/remove/${data}`);

export const getUserDetail = (id) =>
  request.get(`system/user/${id}`);

// 获取部门
export const getDeptList = (name) => request.get(`/system/dept/treeselect?deptName=${name}`)

// 新增部门
// POST
// /unifig
export const postDept = (data) => request.post('/system/dept', data)

// 部门详情
// get
// /system/dept/{deptId}
export const getDeptDetail = (deptId) => request.get(`/system/dept/${deptId}`)


// 修改部门
// PUT
// /system/dept
export const editDept = (data) => request.put('/system/dept', data)


// 删除部门
// DELETE
// /system/dept/{deptId}
export const deleteDept = (deptId) => request.delete(`/system/dept/${deptId}`)

// 获取部门下用户
// system/user/list?pageNum=1&pageSize=10&page=1
// get
export const getDeptUserList = (pageNum, pageSize, page, deptId, userName, roleId) => request.get(`system/user/list?pageNum=${pageNum}&pageSize=${pageSize}&page=${page}&deptId=${deptId}&userName=${userName}&roleId=${roleId}`)

// 新建企业
export const createCompany = (data) => {
  return Promise.resolve(request.post(`/wd/geb/company`, data))
}

// 角色列表复制文档复制地址
// GET
// /system/role/list
export const getRoleList = (pageNum, pageSize, page, deptID) => request.get(`/system/role/list`)


// 邀请用户-根据名字邀请复制文档复制地址
// POST
// /user/reg/invite/name
export const postUserInviteName = (data) => request.post('/user/reg/invite/name', data)

// 链接 邀请用户 
// POST
// /user/reg/invite/oper

export const postUserInvite = (data) => request.post('/user/reg/invite/oper', data)


// 已读
// POST
// /wd/msg/read/{id}

export const readMsg = (id) => request.get(`/wd/msg/read/${id}`)



// 查当前用户rag信息
// 查询rag信息列表-用户权限划分复制文档复制地址
// POST
// /wd/rag/list/role
export const postUserRAGLIST = (data) => request.post('/wd/rag/list/role', data)

// 查当前用户KL信息
export const postUserKLLIST = (data) => request.post('/knowledgeBase/list/role', data)

export const getUrl=()=>request.get('/user/reg/invite/url')