<template>
  <div class="outer">
    <div class="box header">
      <div></div>
      <div><span>免费版</span></div>
      <div><span>专业版</span></div>
      <div><span>旗舰版</span></div>
    </div>
    <div v-for="item in data" class="box">
      <div>{{ item.label }}</div>
      <div v-for="itm in item.data">{{ itm }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
const data = reactive([
  { label: '企业成员', data: ['1个', '10个', '不限'] },
  { label: '应用', data: ['10个', '50个', '不限'] },
  { label: '智能体', data: ['10个', '50个', '不限'] },
  { label: '自定义插件', data: ['3个', '20个', '不限'] },
  { label: '知识库容量', data: ['50万字', '300万字', '2000万字'] },
  { label: '模型调用额度', data: ['5元', '30元/月', '120元/月'] },
  { label: '对话历史', data: ['30天', '180天', '360天'] },
])
</script>

<style scoped lang='scss'>
.outer {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  min-width: 100px;
  box-sizing: border-box;
  z-index: 2;
  border-radius: 8px;
}

.box {
  width: 100%;
  // height: 16.5%;
  display: flex;
  align-items: center;
  background: #FAFAFC;
  padding: 20px;
  border-bottom: 1px solid #F0F2FA;


  div {
    width: 25%;
  }

}

.header {
  background: #FFF;

  div {
    span {
      display: inline-block;
      width: 80px;
      height: 20px;
      border-radius: 11px;
      font-size: 14px;
      text-indent: 24px;
    }
  }

  div:nth-of-type(2) {
    span {
      border: 1px solid #DDE0EB;
      background: #ECEDF0 url('~@/assets/img/pay/icon-mf.png') no-repeat 8px;
      background-size: 12px;
    }
  }

  div:nth-of-type(3) {
    span {
      border: 1px solid #FAEADE;
      background: #FFFBF8 url('~@/assets/img/pay/icon-zy.png') no-repeat 8px;
      background-size: 12px;
    }
  }

  div:nth-of-type(4) {
    span {
      border: 1px solid rgba(250,234,222,0.13);
      background: #2A303A url('~@/assets/img/pay/icon-qj.png') no-repeat 8px;
      background-size: 12px;
      color: #F5CE90;
    }
  }

}

.header div,
.box div:nth-of-type(1) {
  font-weight: 500;
}
</style>