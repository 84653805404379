import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
// import ElementPlus from 'element-plus'
import { createPinia } from 'pinia'
import 'element-plus/dist/index.css'
import './assets/css/style.scss'
import './utils/rem'
import "lib-flexible-computer";
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
// 字体样式
// import './assets/font/font.css'
// import piniaPersist from 'pinia-plugin-persist' //持久化插件
// vueflow样式
import './vueFlow.css'
// markdown
import "github-markdown-css"

import piniaPluginPersistedstate from 'pinia-plugin-persistedstate' //引入持久化插件

import Prism from 'prismjs';
import { btnPerms } from './utils/method'

VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});



const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)


const app = createApp(App)
btnPerms(app)
app.use(ElementPlus, { locale: zhCn, }).use(router).use(pinia).use(VueMarkdownEditor).mount('#app')