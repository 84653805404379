import axios from "axios";
import { ElMessage } from 'element-plus'
// import { getToken } from './method'
import router from '../router'

const fileUrl = process.env.VUE_APP_FILE_URL
const baseurl = process.env.VUE_APP_BASE_URL

const request = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL
})
const token = JSON.parse(localStorage.getItem('token'))
// 添加请求拦截器
request.interceptors.request.use(function (config) {
  config.baseURL = (config.method==='get'&&config.file === 1 ? fileUrl : baseurl);
  const token = JSON.parse(localStorage.getItem('token'));
  const userInfo = localStorage.getItem('userInfo')&&JSON.parse(localStorage.getItem('userInfo'));
  // 在发送请求之前做些什么
  if (token) {
    // const Companyid = JSON.parse(localStorage.getItem('userInfo')).user.companyId || null
    // console.log(route);
    config.headers.Authorization = token;
    config.headers.userId = userInfo?.user.userId || null;
    config.headers.companyId = userInfo?.user.companyId || null;
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (res) {
  // 对响应数据做点什么

  switch (res.data.code) {
    case 200:
      // ElMessage({
      //   message: '操作成功',
      //   type: 'success',
      // })
      break;
    case 400:
      ElMessage.error('请求失败')
      break;
    case 401:
      localStorage.removeItem('token')
      router.push('/Login')
      ElMessage.error('身份认证失效,请重新认证')

      break;
      case 403:
      ElMessage.error(res.data.msg)

      break;
    case 500:
      ElMessage.error(res.data.msg)
      break;
    case 403:
      ElMessage({
        message: res.data.msg,
        type: 'warning',
        plain: true,
      })
      break;
    default:
      break;
  }
  return res.data;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default request