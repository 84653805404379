<template>
  <div class="app" @click="handleClose">
    <el-container class="container">
      <el-header>
        <div class="header-left">
          <img src="../assets/img/logoais.png">
          <h3 class="header-title">集异璧开放平台</h3>
          <div v-show="item.meta.title != item.path" v-for="(item, index) in tabheader" :key="index"
            :class="active == index ? 'header-active-i' : '', item.path == route.path ? 'header-active-i' : ''"
            @click="Routerpush(item, index)" class="header-active">{{
              item.meta.title }} <span class="aaa"></span></div>
        </div>
        <div class="menu" v-show="flag">
          <div class="menu-item">
            <div style="border-bottom: 1px solid #E6E8EB;" @click="personalCenter">个人中心</div>
          </div>
          <div class="menu-item menu-items" @click="removeToken">退出登录</div>
        </div>
        <div class="header-right">
          <div class="company-box" @click.stop="() => { isShowCompanyList = !isShowCompanyList }">
            <img class="icon-team" src="../assets/img/team.png" alt="" srcset="">
            <div class="company-name">{{ userStore.userInfo.user.companyName }}</div>
            <el-icon>
              <ArrowDownBold />
            </el-icon>
            <company-list :isShow="isShowCompanyList" :data="userStore.userInfo.user.subUsers"
              :curCompanyId="userStore.userInfo.user.companyId" :className="'com-list'"
              @clickAdd="handleShowCompanyList('create')" @clickChoose="changeCompany" showAdd showActive></company-list>
          </div>

          <div @click="handleShowCompanyList('choose')" class="buy-box">
            购买
          </div>
          <img class="header-img" v-show="imgFlag" @click="router.push('/msglist')" src="../assets/msglenght.png" alt="">
          <img class="header-img" v-show="!imgFlag" @click="router.push('/msglist')" src="../assets/msglenghts.png"
            alt="">
          <div class="user-box" @click.stop="flag = true">
            <img class="user-img"
              :src="userStore.userInfo.user.avatarUrl ? userStore.userInfo.user.avatarUrl : '../assets/img/avator.png'"
              alt="">
            <span>{{ nickName }}</span>
            <el-icon>
              <ArrowDownBold />
            </el-icon>
          </div>
          <el-tooltip effect="dark" content="帮助文档" placement="bottom">
            <div class="helper-box" @click="handleHelper"></div>
          </el-tooltip>
        </div>
        <!-- <div class="header-right">
          <img class="header-img" src="../assets/提醒.png" alt="">
          <img class="header-img" src="../assets/按键分割线.png" alt="">
          <div class="header-user" @click.stop="flag = true">
            <img class="user-img" src="../assets/img/头像.png" alt="">
            <span style="font-size: .25rem;" v-if="aaa">{{ store.userInfo.user.userName }}</span>
            <el-icon>
              <ArrowDownBold />
            </el-icon>
          </div>
        </div> -->
      </el-header>
      <el-main><router-view class="apppp"></router-view></el-main>
    </el-container>

    <el-dialog class="agreeDialog" v-model="agree" width="500" align-center>
      <div class="user">您好!{{ store.userInfo.user.nickName }}</div>
      <div class="content">{{ content }}</div>
      <template #footer>
        <div class="dialog-footer" style="text-align: left;">
          <button style="margin-right: 10px; border: 0; width: 120px;height: 40px; background: linear-gradient( 330deg, #2E5BFC 0%, #374CFD 54%, #3E6AFE 100%);border-radius: 6px;
            font-size: 16px;color: rgba(255,255,255,0.9);font-weight: 400;font-family: PingFangSC;text-align: center;line-height: 40px;
            " @click="agreeSubmit(2)">同意</button>
          <button
            style="background-color:#fff; margin-right: 10px;width: 72px;height: 40px;border-radius: 6px;border: 1px solid #2E5BFC;font-weight: 400;font-size: 16px;color: #2E5BFC;font-family: PingFangSC;"
            @click="agreeSubmit(3)">
            拒绝
          </button>
          <span @click="agreeSubmit(4)">忽略</span>
        </div>
      </template>
    </el-dialog>
    <el-dialog class="agreeDialog" v-model="loginStatus" width="500" align-center>
      <div class="user">您好!{{ store.userInfo.user.nickName }}</div>
      <div class="content">{{ content }}</div>
      <template #footer>
        <div class="dialog-footer" style="text-align: right;">
          <button style="margin-right: 10px; border: 0; width: 120px;height: 40px; background: linear-gradient( 330deg, #2E5BFC 0%, #374CFD 54%, #3E6AFE 100%);border-radius: 6px;
            font-size: 16px;color: rgba(255,255,255,0.9);font-weight: 400;font-family: PingFangSC;text-align: center;line-height: 40px; 
            " @click="removeToken">我知道了</button>
        </div>
      </template>
    </el-dialog>
  </div>

  <company v-model="isShowCompany" :type="showComType" :data="userStore.userInfo.user.subUsers" @close="closeCompany"
    @choose="chooseCompany"></company>
  <pay v-model="showPay" :initType="initType" :company="curChooseCompany"></pay>

  <!-- 客服进群 -->
  <div :class="['icon-kf-box', kfAct && 'act']" @mouseenter="kfAct = true" @mouseleave="kfAct = false">
    <div v-show="kfAct" class="content-box">
      <div class="title">联系我们</div>
      <div>欢迎加入我们的社群！了解</div>
      <div>讨论关于产品的相关内容</div>
      <img src="../assets/img/callme.png" alt="" srcset="">
    </div>
  </div>
</template>

<script setup>
import { getMsgList } from '@/api/login/login'
import { ref, reactive, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { ArrowDownBold, Plus } from '@element-plus/icons-vue'
import { postUserInvite, readMsg } from '@/api/systemsetting/user'
import { useCounterStore } from '@/store/index'
import { useUserStore } from '@/store/user';
import { checkCompany } from '@/hooks'
import CompanyList from '@/components/CompanyList.vue'
import Company from '@/components/Company.vue'
import Pay from '@/views/pay/Pay'
import { storeToRefs } from 'pinia';

import { ElNotification } from 'element-plus'

const imgFlag = ref(false)
const kfAct = ref(false);
const store = useCounterStore()
const userStore = useUserStore();
const { userInfo } = storeToRefs(userStore)
userStore.setUserInfo();
const showPay = ref(false);
const start = reactive({})
// 同意通知弹框
const agree = ref(false)
const loginStatus = ref(false)
const router = useRouter()
const route = useRoute()
const flag = ref(false)
const tabheader = ref(JSON.parse(localStorage.getItem('userRouter'))[0].children || [{ title: '首页', path: '/index' }]);
const isShowCompany = ref(false);
const showComType = ref('create');
const isShowCompanyList = ref(false);
const initType = ref('product');

const nickName = ref(store.userInfo.user.nickName || '默认用户')
const active = ref('0')

const content = ref('')
const msglist = ref([])
onMounted(() => {
  chat()
  tabheader.value = JSON.parse(localStorage.getItem('userRouter'))[0].children
  nickName.value = store.userInfo.user.nickName
  getMsgList().then(res => {
    if (res.code == 200) {
      msglist.value = res.rows
      if (res.rows.length != 0) {
        content.value = res.rows[0].content
        messsageId.value = res.rows[0].id
        msglist.value.forEach(item => {
          if (item.status == 0) {
            imgFlag.value = true
          }
        })
        if (res.rows[0].msgType != 'reply' && res.rows[0].msgType != 'discuss') {
          if (res.rows[0].status == 0) {
            agree.value = true
          }
        }
      }
    }
  })
})

// 关闭弹窗
const handleClose = () => {
  flag.value = false;
  isShowCompanyList.value = false;
}

// 路由跳转
const Routerpush = (item, i) => {
  active.value = i
  if (item.path == '/RAG') {
    router.push({ path: '/RAGServices' })

  } else if (item.path == '/Systemsettings') {
    console.log(item.path);
    router.push({ path: '/user' })
  }
  else {
    console.log(item.path);
    router.push({ path: item.path })

  }
}
// 退出登录
const removeToken = () => {
  loginStatus.value=false
  localStorage.removeItem('token')
  localStorage.removeItem('userInfo')
  localStorage.removeItem('userRouter')
  router.push('/login')
}
const personalCenter = () => {
  router.push('/personalCenter')
}

// 显示创建新企业 / 多企业购买产品选择企业&单企业直接购买产品
const handleShowCompanyList = (t) => {
  if (t === 'choose' && userStore.userInfo.user?.subUsers.length === 1 && (userInfo.user?.subUsers[0].companyId === userInfo.user?.companyId)) {
    // 只有一个企业的时候直接显示购买产品页
    curChooseCompany = userStore.userInfo.user?.subUsers[0];
    initType.value = 'product';
    showPay.value = true;
  } else {
    // 多个企业去选择企业
    showComType.value = t;
    isShowCompany.value = true;
  }
}
// 关闭
const closeCompany = () => {
  isShowCompany.value = false;
}

// 购买选择企业
let curChooseCompany = reactive({});
const chooseCompany = (o) => {
  curChooseCompany = o;
  closeCompany();
  initType.value = 'product';
  showPay.value = true;
}
const messsageId = ref()
const agreeSubmit = (val) => {
  agree.value = false
  // JSON.parse(localStorage.getItem('userInfo')).user.companyId
  postUserInvite({ agreeStatus: val, messsageId: messsageId.value }).then(res => {
    console.log({ agreeStatus: val, messsageId: messsageId.value });
    // debugger
    console.log(res);
  })
}

const readMsgFn = (id) => {
  agree.value = false
  localStorage.removeItem('token')
  router.push('/logn')
  // readMsg(id).then(res=>{
  //   // console.log(res);
  //   if(res.code==200){
  //     localStorage.removeItem('token')
  //     router.push('/logn')
  //   }
  // })
}

// 切换企业
const changeCompany = (data) => {
  checkCompany({
    userId: data.userId,
    userName: data.userName
  }).then(() => {
    router.go(0);
  });
}

// 信息提示
const chat = () => {
  const eventSource = new EventSource(`${process.env.VUE_APP_BASE_URL}${`/sse/connect/${store.userInfo.user.userId}`}`);
  eventSource.onmessage = async (event) => {
    var eventData = JSON.parse(event.data);
    // 判断事件类型是否为结束事件
    if (eventData.type === "close") {
      deployform.query = ''
      eventSource.close(); // 关闭 SSE 连接
    } else {
      if (eventData.agentName != '登录信息') {
        console.log(eventData.agentName, eventData.agentName == '登录信息');
        ElNotification({
          title: eventData.agentName,
          message: eventData.msg,
          position: 'bottom-right'
        })
      }
      if (eventData.agentName == '登录信息') {
        loginStatus.value = true
        content.value = eventData.msg
      }
    }
  }
}
const handleHelper = () => {
  let { href } = router.resolve({
    path: 'https://lildrdpp8ma.feishu.cn/docx/BLGpdoZ2Uog5kkxP7pfc7sArnge?from=from_copylink',
  })
  window.open(href.substring(1), '_blank');
}
</script>

<style lang="scss" scoped>
.user-box,
.company-name {
  cursor: pointer;
}

.app {
  width: 100%;
  height: 100%;
  background-color: #F2F5FA;
  color: #2D3748;

  .container {
    width: 100%;
    height: 100%;

    .el-header {
      padding: 30px 38px;
      width: 100%;
      height: 50px;
      display: flex;
      background-color: #fff;
      align-items: center;
      justify-content: space-between;
      // border-bottom: 1px solid #ccc;
      position: relative;

      .header-left {
        display: flex;
        align-items: center;

        img {
          width: 26px;
          height: 26px;
        }

        .header-title {
          font-size: 22px;
          color: #2D3748;
          // color: #0000b7;
          margin-left: 20px;
        }

        .header-active {
          color: #2D3748;
          margin-left: 80px;
          font-size: 16px;
        }

        .header-active-i {
          color: #2E5BFC;
          position: relative;

          span {
            display: block;
            width: 100%;
            height: 2px;
            background-color: #2E5BFC;
            position: absolute;
            bottom: -22px;
          }
        }
      }

      .header-right {
        display: flex;
        align-items: center;
        // font-size: 14px;
        font-size: 16px;

        .header-img {
          width: 32px;
          height: 32px;
          margin-right: 24px;
        }

        .company-box {
          // width: 2.5rem;
          display: flex;
          align-items: center;
          margin-right: 20px;
          position: relative;
          border-radius: 8px;
          cursor: default;

          .com-list {
            // padding: 0 0.2rem;
            position: absolute;
            top: 32px;
            right: 0;
            z-index: 5;
            box-shadow: 0px 2px 40px 0px rgba(113, 128, 150, 0.31);
            border-radius: 8px;
          }

          .icon-team {
            width: 32px;
            height: 32px;
          }

          .company-name {
            max-width: 94px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 2px 8px;
          }
        }

        .buy-box {
          width: 68px;
          height: 28px;
          line-height: 28px;
          background: #FFEFCB url('@/assets/img/buy.png') no-repeat 12px center;
          background-size: 12px;
          border-radius: 4px;
          border: 1px solid #DF9545;
          color: #DF9545;
          text-indent: 25px;
          margin-right: 40px;
          position: relative;
          cursor: pointer;

          &::after {
            content: '';
            width: 1px;
            height: 20px;
            background: #CED6E2;
            position: absolute;
            top: 4px;
            right: -020px;
          }
        }


        .user-box {
          display: flex;
          align-items: center;

          img {
            width: 32px;
            border-radius: 50%;
            margin-right: 8px;
          }

          span {
            margin-right: 14px;
          }
        }
      }

      .menu {
        position: absolute;
        width: 200px;
        height: 112px;
        background-color: #fff;
        right: 26px;
        top: 64px;
        border-radius: 8px;
        overflow: hidden;
        z-index: 99;

        .menu-item {
          padding: 0 20px;
          font-size: .175re14px;
          text-align: center;
          height: 56px;
          line-height: 56px;

          &:hover {
            background-color: #ECF0FF;
          }
        }

        .menu-items {
          border: 0;
        }
      }

    }

    .el-main {
      // #apppp{
      width: 100%;
      height: 100%;
      // padding: ;
      padding-top: 0 !important;
      padding: 0 38px;
      padding-bottom: 20px;

      .apppp {
        padding-top: 20px;
      }

      // }

    }
  }

  ::v-deep .agreeDialog {
    width: 600px;
    min-height: 300px;
    background: url('../assets/通知bg@2x.png') no-repeat center center;
    background-size: 100%, 100%;
    border-radius: 10px !important;

    .user {
      font-family: PingFangSC;
      font-weight: 500;
      font-size: 20px;
      color: #2D3748;
      margin-bottom: 28px;
    }

    .content {
      font-family: PingFangSC;
      font-weight: 600;
      font-size: 18px;
      color: #2D3748;
    }

    ::v-deep .el-dialog__header {
      border-radius: 10px !important;

    }

    ::v-deep .el-dialog__body {
      height: 100%;
    }
  }


}

.icon-kf-box {
  width: 60px;
  height: 60px;
  background: #fdfdfd url('~@/assets/img/kf.png') no-repeat center;
  background-size: 36px;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  position: fixed;
  bottom: 200px;
  right: 24px;
  cursor: pointer;
  font-size: 12px;
  z-index: 3;

  &.act {
    background: #2E5BFC url('~@/assets/img/kf1.png') no-repeat center;
    background-size: 36px;
  }

  .content-box {
    width: 200px;
    min-height: 200px;
    padding: 16px 0;
    color: #718096;
    background: #FFF;
    box-shadow: 0px 2px 40px 0px rgba(113, 128, 150, 0.27);
    border-radius: 11px;
    text-align: center;
    position: absolute;
    right: 70px;
    bottom: -80px;
    z-index: 1;

    img {
      width: 100px;
      height: 100px;
      margin-top: 8px;
    }
  }

  // &::after {
  //   content: '';
  //   width: 0;
  //   height: 0;
  //   border: 5px solid transparent;
  //   border-left-color: #FFF;
  //   position: absolute;
  //   left: -10px;
  //   top: 50%;
  //   margin-top: -3px;
  //   z-index: 1;
  // }

  .title {
    color: #2D3748;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
    font-size: 14px;
  }
}

.helper-box {
  width: 32px;
  height: 32px;
  margin-left: 16px;
  cursor: pointer;
  background: url('../assets/img/helper.png') no-repeat center;
  background-size: 100%;

  &:hover {
    background: url('../assets/img/helper1.png') no-repeat center;
    background-size: 100%;
  }
}
</style>

<style lang="scss">
@import url('../assets/css/index.css');

.el-button {
  //  --el-button-font-weight: var(--el-font-weight-primary);
  //  --el-button-border-color: var(--el-border-color);
  //  --el-button-bg-color: var(--el-fill-color-blank);
  //  --el-button-text-color: var(--el-text-color-regular);
  //  --el-button-disabled-text-color: var(--el-disabled-text-color);
  //  --el-button-disabled-bg-color: var(--el-fill-color-blank);
  //  --el-button-disabled-border-color: var(--el-border-color-light);
  //  --el-button-divide-border-color: rgba(255, 255, 255, 0.5);
  // --el-button-hover-text-color: #2E5BFC;
  // --el-button-hover-bg-color: var(--bgchuti) !important;
  //  --el-button-hover-border-color: var(--el-color-primary-light-7);
  //  --el-button-active-text-color: var(--el-button-hover-text-color);
  //  --el-button-active-border-color: var(--el-color-primary);
  //  --el-button-active-bg-color: var(--el-button-hover-bg-color);
  //  --el-button-outline-color: var(--el-color-primary-light-5);
  //  --el-button-hover-link-text-color: var(--el-color-info);
  //  --el-button-active-color: var(--el-text-color-primary);
}

.el-menu {
  :root {
    // --el-menu-active-color: var(--el-color-primary);
    // --el-menu-text-color: var(--el-text-color-primary);
    // --el-menu-hover-text-color: var(--el-color-primary);
    // --el-menu-bg-color: var(--el-fill-color-blank);
    // /* --el-menu-hover-bg-color: var(--el-color-primary-light-9); */
    // --el-menu-item-height: 56px;
    // --el-menu-sub-item-height: calc(var(--el-menu-item-height) - 6px);
    // --el-menu-horizontal-height: 60px;
    // --el-menu-horizontal-sub-item-height: 36px;
    // --el-menu-item-font-size: var(--el-font-size-base);
    // --el-menu-item-hover-fill: var(--el-color-primary-light-9);
    --el-menu-border-color: '' !important;
    --el-menu-base-level-padding: 20px;
    --el-menu-level-padding: 20px;
    --el-menu-icon-width: 24px;
  }
}
</style>